





































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import { namespace } from 'vuex-class'

import GenericModalForm from '@/partials/modals/generic/form.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import CieloInstructionsModal from '@/partials/modals/Events/CieloInstructionsModal.vue'

const sessionModule = namespace('session')

@Component({
  components: {
    CieloInstructionsModal,
    PasswordConfirmField,
    GenericModalForm
  }
})
export default class EventBuyTickets extends Mixins(FlashMessageMixin) {
  @sessionModule.Getter getCPF!: string
  @sessionModule.Getter state!: any
  @sessionModule.Action login!: (login: login) => Promise<any>
  DEBUG = false
  events = []
  payoff = true
  errorMessage = ''
  selectedTicketLink = ''
  freeSubscribeModalIsOpened = false
  selectedTicket = {
    id: '',
    checkoutLink: '',
    eventId: ''
  }

  selectedEvent = {
    id: ''
  }

  subscriberRole: Role = {
    id: '',
    name: ''
  }

  form: Record<string, any> = {
    selectedTicketId: '',
    confirm: ''
  }

  freeForm: Record<string, any> = {
    users: [],
    confirm: '',
    eventSubscriptionTypeId: '',
    errors: {
      confirm: ''
    }
  }

  availableTickets = []

  created () {
    this.selectedEvent = {
      id: this.$route.params.id
    }

    this.DEBUG = Boolean(process.env.NODE_ENV === 'development' && this.$route.query.debug)

    axios.get('roles/event/user')
      .then((response) => {
        this.subscriberRole = response.data.data.find((eventRole: Role) => eventRole.name === 'Inscrito')
      })
  }

  handleSubscription () {
    if (this.selectedTicket.checkoutLink) {
      this.selectedTicketLink = this.selectedTicket.checkoutLink
    } else {
      this.freeSubscribeModalIsOpened = true
      Object.assign(this.freeForm, {
        users: [this.state.user.id],
        eventSubscriptionTypeId: this.selectedTicket.id
      })
    }
  }

  postSubmit (responseMessage: string) {
    this.freeSubscribeModalIsOpened = false

    const flashMessage: flashMessage = {
      message: responseMessage,
      type: 'success'
    }
    // This is DEFINITIVELY GAMBICODE with Ricardo's blessing 🙏🏼
    this.login({
      email: this.state.user.email,
      password: this.freeForm.confirm
    }).then(() => {
      this.$router.push({ name: 'EventShow', params: { id: this.selectedTicket.eventId } }, () => {
        this.setFlashMessage(flashMessage)
      })
    })
  }

  @Watch('selectedEvent')
  @Watch('payoff')
  private onSelectedEventChange () {
    this.errorMessage = ''
    axios.get(`/public/event/${this.selectedEvent.id}/subscriptions/available/${this.getCPF}`, {
      params: {
        payoff: this.DEBUG ? this.payoff : undefined
      }
    })
      .then(response => {
        this.availableTickets = camelCaseKeys(response.data.data, { deep: true })
      })
      .catch(err => {
        const response = err.response

        if (response.status === 404) {
          this.errorMessage = response.data.error
        }
      })
  }

  redirectToPayment () {
    window.location.href = this.selectedTicketLink
  }
}
