





































import { Component, Vue, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const sessionModule = namespace('session')

@Component
export default class CieloInstructionsModal extends Vue {
  @Prop({ default: false }) visible!: boolean
  @sessionModule.Getter state!: any
}
